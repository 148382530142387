import React, { useState, useEffect, useMemo, useCallback, memo, useRef, MutableRefObject } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { CardMedia, Divider, IconButton, ImageListItemBar, MobileStepper, Stack } from '@mui/material';
import CheckIcon from '@mui/icons-material/CheckCircle';
import { LoadingButton } from '@mui/lab';
import { useAppDispatch, useAppSelector } from '../../lib/hook';
import { authSelector, updateUserAndFetch, UserInfo } from '../../lib/ducks/authDuck';


const AvatarContent = (props: any) => {
    const { user, setUser, error } = props;
    const itemData = useMemo(() => {
        let list = [{ name: "default.png" }]
        //console.log("generate itemdata")
        for (let i = 0; i < 17; i++) {
            list.push({ name: i.toString() + '.png' });
        }
        return list;
    }, []);

    return (
        <DialogContent sx={{ margin: 0, display: 'flex' }}>
            <Stack
                direction="column"
                spacing={2}
                sx={{
                    justifyContent: "space-between",
                    //alignItems: "center",
                    flex: 1
                }}>
                <DialogContentText id="alert-dialog-description">
                    Ennen kuin aloitat, täytä alla olevat tiedot.
                </DialogContentText>
                <Stack
                    direction={'row'}
                    gap={4}
                >
                    <TextField
                        autoFocus
                        label={"Etunimi"}
                        error={error.firstName && user.first_name === ''}
                        required
                        margin="dense"
                        id="name"
                        fullWidth
                        value={user.first_name}
                        onChange={(event) => {
                            setUser({ ...user, first_name: event.target.value });
                        }}
                    />
                    <TextField
                        autoFocus
                        label={"Sukunimi"}
                        error={error.lastName && user.last_name === ''}
                        required
                        margin="dense"
                        id="name"
                        fullWidth
                        value={user.last_name}
                        onChange={(event) => {
                            setUser({ ...user, last_name: event.target.value });
                        }}
                    />
                </Stack>
                <Divider />
                <DialogContentText id="alert-dialog-description" color={error.avatar && user.avatar_name === '' ? 'red' : undefined}>
                    Valitse profiilikuvasi
                </DialogContentText>
                <ImageList sx={{ width: 850, height: 420 }} cols={6} rowHeight={128} gap={16}>
                    {itemData.map((item) => (
                        <ImageListItem key={item.name}>
                            <img
                                onClick={() => setUser({ ...user, avatar_name: item.name })}
                                srcSet={`/images/avatar/${item.name}?w=128&h=128&fit=crop&auto=format&dpr=2 2x`}
                                src={`/images/avatar/${item.name}?w=128&h=128&fit=crop&auto=format`}

                                loading="lazy"
                            />
                            <ImageListItemBar

                                sx={{
                                    background:
                                        'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                        'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                }}
                                position="top"
                                actionIcon={
                                    <IconButton
                                        sx={{ color: 'white' }}
                                        onClick={() => setUser({ ...user, avatar_name: item.name })}
                                    >
                                        <CheckIcon color={user.avatar_name === item.name ? "primary" : undefined} />
                                    </IconButton>
                                }
                                actionPosition="left"
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            </Stack>

        </DialogContent>
    )
}

const PictureContent = (props: any) => {

    return (
        <DialogContent sx={{ display: 'flex', margin: 0 }} >
            <Stack
                direction="column"
                spacing={2}
                sx={{
                    justifyContent: "space-between",
                    //alignItems: "center",
                    flex: 1
                }}
            >
                <DialogContentText id="alert-dialog-description">
                    {props.headerText}
                </DialogContentText>
                {props.video ?
                    <CardMedia
                        component='video'
                        //className={classes.media}
                        image={'videos/' + props.picture}
                        autoPlay
                        controls
                    />
                    :
                    <img
                        src={'images/onboarding/' + props.picture}
                        width={'850px'}
                    />
                }

                <DialogContentText id="alert-dialog-description">
                    {props.belowText}
                </DialogContentText>
            </Stack>
        </DialogContent>
    );
}

const headerTexts = [
    '',
    'Tutustu Codex+ -sovelluksen perustoimintoihin katsomalla alla oleva lyhyt video:',
    'Codex+ sovelluksen käyttöönotto: opi hallitsemaan hakujasi ja räätälöi kokemuksesi:',
    'Muista, mitä tarkemmin kirjoitat hakukyselysi, sitä parempia tuloksia saat.Käytä hyväksesi Codex+ sovelluksen tarjoamia työkaluja parantaaksesi hakujesi tehokkuutta ja tarkkuutta.',
    'Keskustelunhallinta Codex+:ssa:',
    'Työskentele tehokkaammin: hyödynnä Codex+ erikoistumisia:'
]

const belowText = [
    '',
    '',
    'Muista, mitä tarkemmin kirjoitat hakukyselysi, sitä parempia tuloksia saat.Käytä hyväksesi Codex+ sovelluksen tarjoamia työkaluja parantaaksesi hakujesi tehokkuutta ja tarkkuutta.',
    'Codex+ pyrkii tehostamaan hakuasi ehdottamalla muotoiluja, jotka ovat linjassa oikeudellisen tietokannan kanssa. Voit käyttää sovelluksen tarjoamaa ehdotusta, joka auttaa parantamaan vastauksen laatua, tai halutessasi pitää alkuperäisen kysymyksen.',
    'Vasemman sivupalkin avulla pysyt ajan tasalla aktiivisista keskusteluistasi. Voit nopeasti tarkastella uusimpia viestejä ja liikkua tehokkaasti eri keskustelujen välillä.',
    'Yläpalkin alasvetovalikko tarjoaa nopean ja helpon tavan vaihtaa eri oikeudenalojen välillä, mikä mahdollistaa työskentelyn useilla juridisen alan erikoistumisalueilla yhden ja saman työkalun kautta.'
]

const pictures = [
    '',
    'onboarding.mp4',
    'Onboarding_kuva_1.png',
    'Onboarding_kuva_2.png',
    'Onboarding_kuva_3.png',
    'Onboarding_kuva_4.png',
]



export const OnboardDialog = (props: any) => {
    const defaultErrorState = { firstName: false, lastName: false, avatar: false, some: false }
    const { userRedux, open, setOpen } = props;
    const [activeStep, setActiveStep] = React.useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useAppDispatch();
    const [user, setUser] = useState<UserInfo | null>(userRedux);
    const [error, setError] = useState(defaultErrorState)

    console.log("activeStep", activeStep)
    useEffect(() => {
        if (activeStep == 0 && userRedux?.first_name != "" && open) {
            setIsLoading(false);
            setActiveStep(1);
        }
    }, [userRedux])

    const onUserSave = () => {
        if (user) {
            setIsLoading(true)
            dispatch(updateUserAndFetch(user));
        }
    }

    const handleNext = () => {
        if (activeStep == 5) {
            setOpen(false);
            setActiveStep(0);
            return;
        }
        if (activeStep == 0) {
            let errorState = { ...defaultErrorState };
            if (user?.first_name === '') {
                errorState.some = true;
                errorState.firstName = true;
            }
            if (user?.last_name === '') {
                errorState.some = true;
                errorState.lastName = true;
            }
            if (user?.avatar_name === '') {
                errorState.some = true;
                errorState.avatar = true;
            }
            if (errorState.some) {
                setError(errorState);
                return;
            }

            onUserSave();
            return
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Dialog
            keepMounted
            open={open}
            //onClose={() => onClick(false, '', '', '')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            PaperProps={{ sx: { minWidth: '900px', minHeight: '800px' } }}
        >
            <DialogTitle id="alert-dialog-title">
                Tervetuloa Codexiin!
            </DialogTitle>
            {activeStep == 0 && user &&
                <AvatarContent
                    user={user}
                    setUser={setUser}
                    error={error}
                />
            }
            {activeStep > 0 &&
                <PictureContent
                    headerText={headerTexts[activeStep]}
                    belowText={belowText[activeStep]}
                    picture={pictures[activeStep]}
                    video={activeStep == 1}

                />
            }
            <Divider sx={{ mx: '24px', marginBottom: '16px' }} />

            <DialogActions>
                <MobileStepper
                    variant="dots"
                    steps={6}
                    position="static"
                    activeStep={activeStep}
                    sx={{ flexGrow: 1 }}
                    nextButton={
                        <LoadingButton
                            onClick={handleNext}
                            variant='contained'
                            loading={isLoading}
                        >
                            {activeStep === 5 ? 'Valmis' : 'Seuraava'}
                        </LoadingButton>
                    }
                    backButton={
                        <Button variant='outlined' onClick={handleBack} disabled={activeStep === 0} //sx={{ display: activeStep === 0 ? 'none' : undefined }}
                        >
                            Edellinen
                        </Button>
                    }
                />
            </DialogActions>
        </Dialog >
    );
};

