import { Routes, Route, Navigate } from 'react-router-dom';
import LoginView from './views/loginView';
import MainLayout from './layouts/mainLayout';
import ChatView from './views/chatView';
import InfoView from './views/infoView';
import { ProfileView } from './views/ProfileView';
import SettingsView from './views/settingsView';
import { useAppSelector } from './lib/hook';

import { authSelector } from './lib/ducks/authDuck';
import ResetPasswordView from './views/resetPasswordView';
import './app.css'
function App() {
   //const token = useSelector((state: RootState) => state.auth.token);
   const token = useAppSelector(authSelector).token;
   const firstName = useAppSelector(authSelector).user?.first_name;

   return (
      <Routes>
         {token &&
            <>
               <Route index element={<MainLayout ><ChatView /> </MainLayout>} />
               <Route path="/info" element={<MainLayout ><InfoView /> </MainLayout>} />
               <Route path="/profile" element={<MainLayout ><ProfileView /> </MainLayout>} />
               <Route path="/settings" element={<MainLayout ><SettingsView /> </MainLayout>} />
               <Route
                  path="*"
                  element={<Navigate to="/" replace={true} />}
               />
            </>
         }
         {!token &&
            <>
               <Route path="/login" element={<LoginView />} />
               <Route path="/reset-password/:token" element={<ResetPasswordView />} />
               <Route
                  path="*"
                  element={<Navigate to="/login" replace={true} />}
               />
            </>
         }


      </Routes >
   )
}



export default App;
