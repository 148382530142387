import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
//import { LoginApi, UserApi } from "../apiActions";
import { RootState } from "../store";
import { UserApi, LoginApi } from "../apiActions";
import { AlertModel } from "./alertType";

export interface AuthState {
    user: User | null,
    isLoading: boolean,
    token: string | null,
    expiry: string | null,
    darkTheme: boolean,
    alert: AlertModel | null
}

export interface User extends UserInfo {
    query_quota: number,
    expires: string,
    roles: Record<string, string>

}
export interface UserInfo {
    email: string,
    first_name: string | undefined,
    last_name: string | undefined,
    phone_number: string | undefined,
    avatar_name: string | undefined,
    organization: string,
}

export const authInitialState = {
    user: null,
    isLoading: false,
    token: null,
    expiry: null,
    darkTheme: false,
    alert: null
} as AuthState


//Thunk functions
// export const fetchUser = createAsyncThunk("auth/fetchUser", async () => {
//     const response = await UserApi.get(null);
//     return response.data;
// })

export const updateUser = createAsyncThunk("auth/updateUser", async (body: any) => {
    const response = await UserApi.put(body, null);
    return response.data;
})

export const login = createAsyncThunk("auth/login", async (body: any) => {
    const response = await LoginApi.login(body, null);
    return response.data;
})

export const changePassword = createAsyncThunk("auth/changePassword", async (body: any) => {
    const response = await LoginApi.changePassword(body, null);
    return response.data;
})

export const updateUserAndFetch = (body: UserInfo) => async (dispatch: any) => {
    await dispatch(updateUser(body))
    //dispatch(fetchUser());
}

export const requestLogoutAndLogout = () => async (dispatch: any) => {
    await LoginApi.logout({ validateStatus: false });
    dispatch(logout());
}

export const deleteUserAndLogout = () => async (dispatch: any) => {
    await UserApi.delete({ validateStatus: false });
    dispatch(logout());
}

export const requestLogoutAllAndLogout = () => async (dispatch: any) => {
    await LoginApi.logoutAll({ validateStatus: false });
    dispatch(logout());
}



const authSlice = createSlice({
    name: 'auth',
    initialState: authInitialState,
    reducers: {
        logout: () => authInitialState,
        setDarkTheme(state, action: PayloadAction<boolean>) {
            state.darkTheme = action.payload
        },
        setAlert(state, action: PayloadAction<AlertModel>) {
            state.alert = action.payload
        },
        resetAlert: (state) => { state.alert = null },
    },
    extraReducers: (builder) => {
        builder
            //fetchUser
            // .addCase(fetchUser.pending, (state, action) => {
            //     state.isLoading = true;
            // })
            // .addCase(fetchUser.fulfilled, (state, action) => {
            //     state.isLoading = false;
            //     state.user = action.payload;
            // })
            // .addCase(fetchUser.rejected, (state, action) => {
            //     state.isLoading = false;
            //     console.log("error while getting user:", action)
            // })
            //updateUser
            .addCase(updateUser.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.user = action.payload;
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.isLoading = false;
                console.log("error while updateUser:", action)
            })

            //login
            .addCase(login.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoading = false;
                //state.user = action.payload.user;
                state.user = { ...action.payload.user };
                state.expiry = action.payload.expiry;
                state.token = action.payload.token;
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoading = false;
                console.log("error while login:", action)
                state.alert = { message: 'Väärä käyttäjänimi tai salasana!', type: 'error', key: (new Date()).toString() }
            })

            //changePassword
            .addCase(changePassword.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(changePassword.fulfilled, (state, action) => {
                state.isLoading = false;
                state.alert = { message: 'Salasanan vaihto onnistui!', type: 'success', key: (new Date()).toString() }
            })
            .addCase(changePassword.rejected, (state, action) => {
                state.isLoading = false;
                console.log("error while login:", action)
                state.alert = { message: 'Salasanan vaihto epäonnistui!', type: 'error', key: (new Date()).toString() }
            })
    }
})

export const { logout, setDarkTheme, resetAlert, setAlert } = authSlice.actions;

export default authSlice.reducer;
export const authSelector = (state: RootState) => state.auth;